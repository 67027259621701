import { Heading, TimeUtil, useFeatureFlags } from 'cuenect-web-core'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Button,
  ContentContainer,
  InnerContainer,
  LineContainer,
  Link,
  Paragraph,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { ProgramContainer } from '../components/organisms/programContainer'
import { eventConfig } from '../config'
import { useVodContext } from '../utility'
import { isPostEvent } from '../config'

const RENAME_AUDITORIUM = '2021-10-06T04:00Z'
const HIDE_MEET_THE_EXPERT_TIME = '2021-10-07T16:00Z'

const getRenameAuditorium = () => {
  const now = TimeUtil.getNowUtc()

  return now.isAfter(RENAME_AUDITORIUM)
}

const getHideMte = () => {
  const now = TimeUtil.getNowUtc()

  return now.isAfter(HIDE_MEET_THE_EXPERT_TIME)
}

const ProgramPage = (params: PageParams) => {
  const {
    pageContext: { lang, program },
  } = params

  const { phase3 } = useFeatureFlags()

  const { dispatch: vodDispatch } = useVodContext()

  const noFrame =
    new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    ).get('noFrame') === 'true'

  const forceFilterSlug =
    new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    ).get('forceFilterSlug') || undefined

  const [scroller, setScroller] = React.useState(null)

  const agendaProgram = program || 'stage'
  const { t } = useTranslation('tech-sessions')
  const [loaded, setLoaded] = React.useState(false)

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  return (
    <PageFrame
      {...params}
      noFrame={noFrame}
      perectScroll={false}
      pageName="tech-sessions"
    >
      {loaded && (
        <ContentContainer>
          <InnerContainer width="100%">
            <LineContainer className="LineContainer">
              <Heading>{t('headline')}</Heading>
              <Paragraph style={{ marginBottom: 0 }}>{t('subline')}</Paragraph>
            </LineContainer>
          </InnerContainer>
          <ProgramContainer
            source={eventConfig.sources[lang]}
            subdivision={agendaProgram}
            slug={`techmeetup-${lang}`}
            postEvent={isPostEvent()}
            forceFilterSlug={forceFilterSlug}
          />
        </ContentContainer>
      )}
    </PageFrame>
  )
}

export default ProgramPage
